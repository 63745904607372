import React from 'react'

export default props => (
  <svg viewBox="0 0 48 48" style={{ background: '#1da1f3' }} {...props}>
    <path
      fill="#fff"
      d="M18.432 36.192c11.316 0 17.508-9.384 17.508-17.508 0-.264 0-.528-.012-.792a12.536 12.536 0 0 0 3.071-3.192 12.451 12.451 0 0 1-3.54.972 6.19 6.19 0 0 0 2.713-3.408 12.24 12.24 0 0 1-3.912 1.487 6.142 6.142 0 0 0-4.488-1.943 6.161 6.161 0 0 0-6.156 6.156c0 .48.06.948.156 1.404-5.112-.252-9.648-2.712-12.684-6.432a6.155 6.155 0 0 0-.828 3.096 6.169 6.169 0 0 0 2.736 5.124 6.224 6.224 0 0 1-2.784-.768v.084a6.167 6.167 0 0 0 4.932 6.036 5.999 5.999 0 0 1-1.62.216 6.077 6.077 0 0 1-1.152-.108 6.153 6.153 0 0 0 5.748 4.272 12.341 12.341 0 0 1-7.644 2.64c-.492 0-.984-.024-1.464-.085a17.503 17.503 0 0 0 9.42 2.749"
    />
  </svg>
)
