// @auto-index

import s1r1633x943 from './s1r1633x943.jpg'
import s2r1633x943 from './s2r1633x943.jpg'
import s3r1633x943 from './s3r1633x943.jpg'
import s4r1633x943 from './s4r1633x943.jpg'
import s5r1633x943 from './s5r1633x943.jpg'

export { default as s1r1633x943 } from './s1r1633x943.jpg'
export { default as s2r1633x943 } from './s2r1633x943.jpg'
export { default as s3r1633x943 } from './s3r1633x943.jpg'
export { default as s4r1633x943 } from './s4r1633x943.jpg'
export { default as s5r1633x943 } from './s5r1633x943.jpg'

export default {
  s1r1633x943,
  s2r1633x943,
  s3r1633x943,
  s4r1633x943,
  s5r1633x943
}
