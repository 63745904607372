export default [
  'Functional programming',
  'Language design',
  'Deep neural networks',
  'UX',
  'App development',
  'DX',
  'Security',
  'Performance',
  'User interface design',
  'Dogs 🐶'
]
