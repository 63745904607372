// @auto-index

import _1r1366x768 from './1r1366x768.jpg'
import _2r1366x768 from './2r1366x768.jpg'
import _3r1366x768 from './3r1366x768.jpg'
import _4r1366x768 from './4r1366x768.jpg'
import _5r1366x768 from './5r1366x768.jpg'
import _6r1366x768 from './6r1366x768.jpg'
import _7r1366x768 from './7r1366x768.jpg'

export { default as _1r1366x768 } from './1r1366x768.jpg'
export { default as _2r1366x768 } from './2r1366x768.jpg'
export { default as _3r1366x768 } from './3r1366x768.jpg'
export { default as _4r1366x768 } from './4r1366x768.jpg'
export { default as _5r1366x768 } from './5r1366x768.jpg'
export { default as _6r1366x768 } from './6r1366x768.jpg'
export { default as _7r1366x768 } from './7r1366x768.jpg'

export default {
  _1r1366x768,
  _2r1366x768,
  _3r1366x768,
  _4r1366x768,
  _5r1366x768,
  _6r1366x768,
  _7r1366x768
}
