// @auto-index

import _1 from './1.jpg'
import _2 from './2.jpg'
import _3 from './3.jpg'
import _4 from './4.jpg'
import _5 from './5.jpg'
import _6 from './6.jpg'
import _7 from './7.jpg'
import _8 from './8.jpg'

export { default as _1 } from './1.jpg'
export { default as _2 } from './2.jpg'
export { default as _3 } from './3.jpg'
export { default as _4 } from './4.jpg'
export { default as _5 } from './5.jpg'
export { default as _6 } from './6.jpg'
export { default as _7 } from './7.jpg'
export { default as _8 } from './8.jpg'

export default {
  _1,
  _2,
  _3,
  _4,
  _5,
  _6,
  _7,
  _8
}
