// @auto-index

import icon from './icon.jpg'
import screenshots from './screenshots'

export { default as icon } from './icon.jpg'
export { default as screenshots } from './screenshots'

export default {
  icon,
  screenshots
}
