import React from 'react'

export default props => (
  <svg
    viewBox="0 0 48 48"
    style={{ background: '#1b1817', padding: 4 }}
    {...props}
  >
    <path
      fill="#fff"
      d="M24 0C10.747 0 0 10.744 0 24c0 10.604 6.877 19.598 16.413 22.773 1.199.222 1.64-.52 1.64-1.155 0-.57-.023-2.462-.033-4.468-6.677 1.453-8.086-2.832-8.086-2.832-1.092-2.773-2.664-3.511-2.664-3.511-2.178-1.49.163-1.46.163-1.46 2.411.17 3.68 2.475 3.68 2.475 2.14 3.668 5.614 2.608 6.984 1.994.214-1.55.837-2.61 1.523-3.209C14.29 34 8.686 31.942 8.686 22.747c0-2.62.937-4.762 2.473-6.443-.25-.604-1.07-3.045.233-6.35 0 0 2.015-.646 6.602 2.46a22.993 22.993 0 0 1 6.007-.81c2.039.011 4.094.278 6.011.81 4.58-3.106 6.594-2.46 6.594-2.46 1.307 3.305.484 5.745.236 6.35 1.538 1.68 2.469 3.822 2.469 6.443 0 9.217-5.614 11.246-10.958 11.84.86.746 1.627 2.206 1.627 4.445 0 3.211-.028 5.797-.028 6.586 0 .64.433 1.387 1.648 1.153C41.131 43.594 48 34.6 48 24 48 10.744 37.256 0 24 0"
    />
    <path
      fill="#fff"
      d="M9.09 34.459c-.053.119-.24.155-.41.073-.175-.08-.273-.241-.216-.36.05-.124.239-.158.412-.075.175.079.274.242.214.362m.973 1.084c-.115.106-.338.057-.49-.111-.158-.167-.187-.391-.071-.5.118-.105.335-.055.492.112.158.17.188.391.067.5m.948 1.38c-.147.103-.389.007-.536-.206-.148-.214-.148-.47.003-.572.149-.102.385-.01.536.202.147.216.147.473-.003.577m1.295 1.336c-.13.145-.41.106-.616-.093-.21-.193-.268-.467-.136-.612.133-.147.414-.105.622.09.208.195.27.47.13.615m1.79.775c-.06.188-.328.272-.6.194-.271-.083-.45-.303-.394-.493.056-.189.327-.278.6-.192.272.08.45.3.393.49m1.965.145c.007.197-.225.362-.509.365-.287.007-.52-.153-.523-.35 0-.199.226-.36.512-.365.286-.006.52.153.52.349m1.828-.31c.033.192-.165.391-.448.444-.28.05-.537-.069-.572-.26-.034-.197.167-.396.445-.448.284-.05.538.068.575.264"
    />
  </svg>
)
